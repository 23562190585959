import $ from 'jquery';

// a basic accordion.
$(function() {
  $('.accordion .accordion-title').on( 'click', function() {

    var $title = $(this);
    var $content = $title.next();
  
    $title.siblings().removeClass('active');
    $title.addClass('active');
    $content.addClass('active');     
  });
})

if( $( '.news-card' ).length ) {

  $(document).on('click', '.js-button', () => {
    $('.news-card.hide-card').addClass('fadein-card');
    $('.js-button').hide();
  });
}
